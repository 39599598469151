import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Signal, computed, effect, inject, input } from '@angular/core';
import { SeriesOption } from 'echarts';
import { ECharts } from 'echarts/core';
import { Deposit } from '../../models/deposit.model';
import { DatePipe } from '@angular/common';
import { isThisMonth, subDays } from 'date-fns';
import { Router } from '@angular/router';
import { FromDictionaryPipe, LanguageService, SetValueToDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { Tip } from 'src/app/tips/models';

@Component({
  selector: 'app-insights-graph-year',
  templateUrl: './insights-graph-year.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsGraphYearComponent {
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  private setValueDict = new SetValueToDictionaryPipe();
  private cd = inject(ChangeDetectorRef);
  private datePipe = inject(DatePipe);
  private router = inject(Router);

  @Input() tips: Tip[] = [];

  deposits = input<Deposit[]>([]);
  year = input.required<number>();
  averageDepositsDistrict = input<number>(5);
  district = input<string>('Stadshagen');

  activeYearDeposits$ = computed(() => this.deposits().filter(a => new Date(a.depositedOn).getFullYear() === this.year()));
  averageDepositsAddress$ = computed(() =>
    Math.round(this.months$().reduce((sum, current) => sum + +current.value, 0) / this.months$().length)
  );

  minDate$ = computed(() => new Date(this.year(), 0, 1));
  maxDate$ = computed(() => {
    const maxDate = this.activeYearDeposits$().reduce(
      (max, obj) => (new Date(obj.depositedOn) > max ? new Date(obj.depositedOn) : max),
      new Date(this.activeYearDeposits$()?.[0]?.depositedOn)
    );

    if (maxDate?.getUTCMonth() < new Date(Date.now()).getUTCMonth()) {
      maxDate.setUTCMonth(new Date(Date.now()).getUTCMonth());
    }

    return maxDate;
  });

  months$ = computed(() => {
    if (!this.activeYearDeposits$().length || !this.minDate$() || !this.maxDate$()) {
      return [];
    }

    const result: any[] = [];

    for (let date = new Date(this.minDate$()); date <= this.maxDate$(); date.setMonth(date.getMonth() + 1)) {
      const count = this.activeYearDeposits$().filter(
        obj => new Date(obj.depositedOn).getFullYear() === date.getFullYear() && new Date(obj.depositedOn).getMonth() === date.getMonth()
      ).length;

      result.push({ dataGroupId: this.getGroupId(date, 'month'), value: count || '', date: new Date(date) });
    }

    return result;
  });

  echartsInstance?: ECharts;
  echartOptions$: Signal<any> = computed(() => {
    const months = this.months$();
    return {
      textStyle: {
        fontFamily: '"Open Sans", Verdana, sans-serif',
      },

      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'none',
        },
      },
      xAxis: {
        type: 'category',
        data: [...months.map(a => this.datePipe.transform(a.date, 'MMM') || '')],
        axisLine: {
          //onZero: true,
          lineStyle: {
            color: '#D7DEE3',
          },
        },
        axisLabel: {
          color: '#979797',
          fontWeight: 'bold',
          rotate: 0,
        },
      },
      media: [
        {
          // For screens smaller than 768px
          query: {
            maxWidth: 768,
          },
          option: {
            xAxis: {
              axisLabel: {
                rotate: 45, // rotate the labels by 45 degrees
              },
            },
          },
        },
      ],
      yAxis: {
        type: 'value',
        boundaryGap: [0, 0],
        //interval: 1,
        min: 0,
        max: (a: any) => {
          return a.max < 10 ? 10 : Math.ceil(a.max / 5) * 5; // round up to nearest of 5, but if the lowest is lower than 10 use 10
        },
        axisLine: {
          onZero: true,
        },
        axisLabel: {
          color: '#254B8A',
        },
      },
      grid: {
        left: 35, // Change this value to adjust the position of the chart grid
        right: 0,
        top: 10,
      },
      dataGroupId: '',
      animationDurationUpdate: 500,
      series: {
        type: 'bar',
        id: 'ledegingen',
        name: `${this.setValueDict.transform(this.dict.transform('inzicht-grafiek-gebruiker-stortingen-label'), [{ key: 'jaar', value: this.year() + '' }])}`,
        // z: 100,
        data: [
          ...months.map(a => {
            if (isThisMonth(a.date)) {
              // console.log(+a.value);
              //a.value = +a.value;
            }
            return isThisMonth(a.date)
              ? {
                  ...a,
                  itemStyle: {
                    color: '#8ac249',
                  },
                }
              : a;
          }),
        ],
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: '#7A8F99',
            width: 2,
          },
          tooltip: {
            show: true,
          },
          data: [
            {
              yAxis: this.averageDepositsDistrict(),
              // name: `${this.districtName} gemiddeld in ${this.year}`,
              name: `${this.setValueDict.transform(this.dict.transform('inzicht-grafiek-wijk-gemiddelde-label'), [
                { key: 'wijk', value: this.district() },
                { key: 'jaar', value: this.year() + '' },
              ])}`,
            },
            // { type: 'average', name: 'Jouw gemiddelde', lineStyle: { color: '#254B8A' } },
          ],
        },
        //barWidth: 10,
        barMinHeight: 10,
        barMaxWidth: 80,
        barCategoryGap: 10,
        barGap: 10,
        itemStyle: {
          borderRadius: [10, 10, 0, 0],
          color: '#254B8A',
        },
      } as unknown as SeriesOption,
    };
  });

  legend$ = computed(() => {
    const opts = this.echartOptions$();
    if (!Array.isArray(opts.series)) {
      return [opts.series];
    }

    return opts.series;
  });

  chartChangeEffect = effect(() => {
    const opts = this.echartOptions$();

    this.echartsInstance?.setOption(
      {
        ...opts,
      },
      true
    );
  });

  getGroupId(date: Date, type: 'day' | 'month' | 'year') {
    switch (type) {
      case 'year':
        return this.datePipe.transform(date, 'y');
      case 'month':
        return this.datePipe.transform(date, 'MMMM');
      case 'day':
      default:
        return this.datePipe.transform(date, 'd'); // d MMM
    }
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
  }

  resizeChart() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  // This will not switch main view type
  // This will zoom in on the bar, if it has any subdata
  handleChartClick(event: any) {
    const clickedMonth = event.data?.date?.getMonth();

    if (event.data) {
      this.router.navigate([], {
        queryParams: { month: clickedMonth },
        queryParamsHandling: 'merge',
      });
      this.cd.detectChanges();
    }
  }

  get lastPublishedOnDate() {
    return subDays(new Date(), 8);
  }
}
