<div class="row insights-list">
  <main class="col-md-8">
    <article>
      <header class="insight-overview__heading mb-7">
        <h2 class="h4 mb-0 text-secondary font-secondary">{{ title }}</h2>
        <div class="text-secondary type-body-sm fw-bold">
          <strong class="me-1">{{ yearCount }}</strong>
          <span class="d-none d-md-inline">{{ 'aanbiedingen-label' | fromDictionary }}</span>
          <span class="d-md-none">keer</span>
        </div>
      </header>

      <dl class="mb-6" *ngFor="let storting of groupedDeposits">
        <dt class="text-secondary fw-bold h6 font-secondary insights-list__subtitle">
          {{ storting.month | date : 'MMMM' }}
          <span class="text-lowercase" *ngIf="isThisMonth(storting.month)">tot nu toe</span>
        </dt>
        <dd>
          <app-deposit-card
            [link]="[title, storting.month | date : 'M']"
            [title]="'inzicht-lijst-card-titel' | fromDictionary"
            [amount]="storting.deposits.length"
            [type]="isThisMonth(storting.month) ? 'primary' : 'light'"
          ></app-deposit-card>
        </dd>
      </dl>
    </article>

    <div class="insight-emptystate empty-state" *ngIf="!groupedDeposits">
      <p class="mb-2">{{ 'inzicht-geen-aanbiedingen-zichtbaar-tekst' | fromDictionary }} {{ title.toLowerCase() }}.</p>
      <div class="type-body-xxs" [innerHTML]="'inzicht-geen-aanbiedingen-zichtbaar-hulptekst' | fromDictionary"></div>
    </div>
  </main>
</div>
