import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { AddressDistrict, DistrictStatistics } from '../../models';
import * as actions from '../actions/district-statistics.action';

export interface DistrictStatisticsState {
  district?: AddressDistrict;
  entities: { [id: number]: DistrictStatistics };
  loaded: number[];
  loading: number[];
  error?: any;
}

export const initialState: DistrictStatisticsState = {
  district: undefined,
  entities: {},
  loaded: [],
  loading: [],
};

export const districtStatisticsReducer = createReducer(
  initialState,
  on(actions.LoadDistrictStatistics, (state: DistrictStatisticsState, { year }) => {
    const y = year || new Date().getFullYear();
    return {
      ...state,
      loading: [...state.loading, y],
    };
  }),
  on(actions.LoadDistrictStatisticsSuccess, (state: DistrictStatisticsState, { district, statistics, year }) => {
    const y = year || new Date().getFullYear();
    const entities = ToEntities(statistics, 'id', state.entities);
    return {
      ...state,
      district,
      entities,
      loading: state.loading.filter(a => a !== y),
      loaded: [...state.loaded, y],
    };
  }),
  on(actions.LoadDistrictStatisticsFail, (state: DistrictStatisticsState, { error, year }) => {
    const y = year || new Date().getFullYear();
    return {
      ...state,
      loading: state.loading.filter(a => a !== y),
      loaded: state.loaded.filter(a => a !== y),
      error,
    };
  })
);

export const getDistrictStatisticsEntities = (state: DistrictStatisticsState) => state.entities;
export const getDistrictStatisticsLoading = (state: DistrictStatisticsState) => state.loading;
export const getDistrictStatisticsLoaded = (state: DistrictStatisticsState) => state.loaded;
export const getDistrict = (state: DistrictStatisticsState) => state.district;
