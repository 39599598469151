import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InsightDashboardComponent } from './containers/insight-dashboard/insight-dashboard.component';
import { DepositsGuard, districtStatisticsCurrentYearGuard, districtStatisticsGuard } from './guards';
import { DepositsCurrentYearGuard } from './guards/deposits-current-year.guard';
import { MonthDetailViewComponent } from './containers/month-detail-view/month-detail-view.component';
import { GuideComponent } from './containers/guide/guide.component';
import { TipInsightsGuard } from '../tips/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: InsightDashboardComponent,
    canActivate: [DepositsGuard, DepositsCurrentYearGuard, districtStatisticsGuard, districtStatisticsCurrentYearGuard, TipInsightsGuard],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: ':year/:month',
    pathMatch: 'full',
    component: MonthDetailViewComponent,
    canActivate: [DepositsGuard, DepositsCurrentYearGuard, districtStatisticsGuard, districtStatisticsCurrentYearGuard, TipInsightsGuard],
  },
  {
    path: 'guide',
    pathMatch: 'full',
    component: GuideComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InsightRoutingModule {}
