import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, Subject, of, takeUntil } from 'rxjs';
import { getGroupedDepositsByMonthYear } from '../../store';
import { getRouterState } from 'src/app/store';

@Component({
  selector: 'app-month-detail-view',
  templateUrl: './month-detail-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthDetailViewComponent implements OnInit, OnDestroy {
  deposits$: Observable<any> = of([]);
  routerState$!: Observable<any>;
  @ViewChild('DashboardMain') elMain: ElementRef<any> | undefined;
  pageTitle: string = '';

  private _unsubscribe = new Subject<void>();

  constructor(private store: Store, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.routerState$ = this.store.select(getRouterState);
    this.deposits$ = this.store.select(getGroupedDepositsByMonthYear);

    this.routerState$.pipe(takeUntil(this._unsubscribe)).subscribe(state => {
      const year = +state.state.params['year'];
      const month = +state.state.params['month'];

      let date = new Date();
      date.setFullYear(year);
      date.setMonth(month - 1);

      this.pageTitle = date.toLocaleString('nl-nl', { month: 'long', year: 'numeric' })
        .replace(/\b\w/g, l => l.toUpperCase());
    });
  }

  getTotal(d: any) {
    if (d) {
      return d.reduce((accumulator: any, currentDeposit: any) => accumulator + currentDeposit.deposits.length, 0);
    } else {
      return 0;
    }
  }

  goBack() {
    window.history.back();
  }

  gotoMain($event: any) {
    this.elMain?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
