import { createAction, props } from '@ngrx/store';
import { AddressDistrict, DistrictStatistics } from '../../models';

export const LoadDistrictStatistics = createAction('[District Statistics] Load District Statistics', props<{ year?: number }>());
export const LoadDistrictStatisticsSuccess = createAction(
  '[District Statistics] Load District Statistics Success',
  props<{ year?: number; district: AddressDistrict; statistics: DistrictStatistics[] }>()
);
export const LoadDistrictStatisticsFail = createAction(
  '[District Statistics] Load District Statistics Fail',
  props<{ error: any; year?: number }>()
);
