import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import * as fromStore from '../store';
import { combineLatest, of } from 'rxjs';

export const districtStatisticsCurrentYearGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  var year: number = new Date().getFullYear();

  return combineLatest([store.select(fromStore.getDistrictStatisticsLoaded), store.select(fromStore.getDistrictStatisticsLoading)])
    .pipe(
      tap(([loaded, loading]) => {
        if (!loaded.some(a => a === year) && !loading.some(a => a === year)) {
          store.dispatch(fromStore.LoadDistrictStatistics({ year }));
        }
      }),
      map(([loaded, loading]) => loaded.some(a => a === year)),
      filter(loaded => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
