<app-insight-header (gotoYear)="gotoMain($event)"></app-insight-header>

<section class="bg-space-gray-200 insight-dashboard section-inset-y">
  <header class="insight-dashboard__header">
    <div class="container d-flex align-items-center justify-content-between">
      <div class="insight-dashboard__selector">
        @if (prevYear$(); as prevYear) {
          <button
            [routerLink]="[]"
            [queryParams]="{ year: prevYear, month: null }"
            [queryParamsHandling]="'merge'"
            [routerLinkActive]="'button--primary'"
            [disabled]="prevYear < availableYears.min"
            class="button button-icon button--md"
          >
            <fstr-icon anchor="icon-arrow-left"></fstr-icon>
          </button>
        }
        <strong class="px-3">{{ activeYear$() }}</strong>
        @if (nextYear$(); as nextYear) {
          <button
            [routerLink]="[]"
            [queryParams]="{ year: nextYear, month: null }"
            [queryParamsHandling]="'merge'"
            [routerLinkActive]="'button--primary'"
            [disabled]="nextYear > availableYears.max"
            class="button button-icon button--md"
          >
            <fstr-icon anchor="icon-arrow-right"></fstr-icon>
          </button>
        }
      </div>

      <div class="insight-dashboard__view-mode">
        @if (viewMode() === InsightsViewMode.LIST) {
          <button [routerLink]="[]" [queryParams]="{ viewMode: 0 }" [queryParamsHandling]="'merge'" class="button button--viewmode">
            <fstr-icon anchor="icon-grafiek"></fstr-icon>
            <span class="d-none d-md-block">{{ 'inzicht-bekijk-grafiek-knoptekst' | fromDictionary }}</span>
          </button>
        } @else if (viewMode() === InsightsViewMode.GRAPH) {
          <button [routerLink]="[]" [queryParams]="{ viewMode: 1 }" [queryParamsHandling]="'merge'" class="button button--viewmode">
            <fstr-icon anchor="icon-list-view"></fstr-icon>
            <span class="d-none d-md-block">{{ 'inzicht-bekijk-lijst-knoptekst' | fromDictionary }}</span>
          </button>
        }
      </div>
    </div>
  </header>
  <div class="container" #DashboardMain>
    @if (viewMode() === InsightsViewMode.GRAPH) {
      @if (activeMonth$() === null) {
        <app-insights-graph-year
          [district]="district$()?.district || ''"
          [averageDepositsDistrict]="districtMean$()"
          [deposits]="deposits$()"
          [year]="activeYear$()"
          [tips]="tips$()"
        ></app-insights-graph-year>
      } @else {
        <app-insights-graph-month
          [deposits]="deposits$()"
          [year]="activeYear$()"
          [month]="activeMonth$()!"
          [averageDepositsDistrict]="districtMean$()"
        ></app-insights-graph-month>
      }
    } @else if (viewMode() === InsightsViewMode.LIST) {
      <app-insights-list
        [title]="activeYear$()"
        [timeMode]="timeMode()"
        [groupedDeposits]="grouped"
        *ngIf="groupedDeposits$() as grouped"
      ></app-insights-list>
    }
  </div>
</section>
