import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddressDistrict, Deposit, DistrictStatistics } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getDeposits(year?: number) {
    if (year) {
      // return this.http.get<Deposit[]>(`https://rova-inzicht.fstr.io/api/my-rova/insights/deposits?year=${year}`);
      return this.http.get<Deposit[]>(`${this.BASE_URL}api/my-rova/insights/deposits?year=${year}`);
    }
    // return this.http.get<Deposit[]>(`https://rova-inzicht.fstr.io/api/my-rova/insights/deposits`);
    return this.http.get<Deposit[]>(`${this.BASE_URL}api/my-rova/insights/deposits`);
  }

  getDistrictStatistics(year?: number) {
    if (year) {
      return this.http.get<{ district: AddressDistrict; statistics: DistrictStatistics[] }>(
        `${this.BASE_URL}api/my-rova/insights/district-statistics?year=${year}`
      );
    }
    return this.http.get<{ district: AddressDistrict; statistics: DistrictStatistics[] }>(
      `${this.BASE_URL}api/my-rova/insights/district-statistics`
    );
  }
}
