import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { InsightRoutingModule } from './insight-routing.module';
import { IconModule } from '@teamfoster/sdk/icon';
import { DepositCardComponent } from './components/deposit-card/deposit-card.component';
import { InsightHeaderComponent } from './components/insight-header/insight-header.component';

// Echarts
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import bar charts, all with Chart suffix
import { BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/theme/macarons.js';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { InsightDashboardComponent } from './containers/insight-dashboard/insight-dashboard.component';
import { CoreModule } from '../core/core.module';
import { InsightsGraphYearComponent } from './components/insights-graph-year/insights-graph-year.component';
import { InsightsListComponent } from './components/insights-list/insights-list.component';
import { ImageModule } from '@teamfoster/sdk/image';
import * as fromStore from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CountToModule } from '@teamfoster/sdk/count-to';
import { MonthDetailViewComponent } from './containers/month-detail-view/month-detail-view.component';
import { GuideComponent } from './containers/guide/guide.component';
import { DepositCompareCardComponent } from './components/deposit-compare-card/deposit-compare-card.component';
import { InsightsGraphMonthComponent } from './components/insights-graph-month/insights-graph-month.component';

echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

@NgModule({
  declarations: [
    DepositCardComponent,
    DepositCompareCardComponent,
    InsightHeaderComponent,
    InsightDashboardComponent,
    InsightsGraphYearComponent,
    InsightsGraphMonthComponent,
    InsightsListComponent,
    MonthDetailViewComponent,
    GuideComponent,
  ],
  imports: [
    CommonModule,
    InsightRoutingModule,
    NgxEchartsModule.forRoot({ echarts }),
    IconModule,
    ImageModule,
    DictionaryNgrxModule,
    CoreModule,
    CountToModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [DepositCardComponent],
  providers: [
    DatePipe,
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') }),
    },
  ],
})
export class InsightModule {}
