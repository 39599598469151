import { ChangeDetectionStrategy, Component, computed, effect, inject, input, viewChild } from '@angular/core';
import { InsightsTimeMode, InsightsViewMode } from '../../models';
import { Store } from '@ngrx/store';
import { getDistrict, getGroupedDepositsArray, getOrderedDeposits, getOrderedDistrictStatisticsByYear } from '../../store';
import { Go } from 'src/app/store';
import { getRandomInsightsTips } from 'src/app/tips/store';

@Component({
  selector: 'app-insight-dashboard',
  templateUrl: './insight-dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightDashboardComponent {
  private store = inject(Store);

  viewMode = input<InsightsViewMode, string>(InsightsViewMode.GRAPH, { transform: v => +v || InsightsViewMode.GRAPH });
  timeMode = input<InsightsTimeMode, string>(InsightsTimeMode.YEAR, { transform: v => +v || InsightsTimeMode.YEAR });

  activeYear$ = input<number, string>(new Date().getFullYear(), { alias: 'year', transform: v => +v || 0 });
  activeMonth$ = input<number | null, string>(null, { alias: 'month', transform: v => (isNaN(+v) ? null : +v) });

  prevYear$ = computed(() => this.activeYear$() - 1);
  nextYear$ = computed(() => this.activeYear$() + 1);

  deposits$ = this.store.selectSignal(getOrderedDeposits);
  groupedDeposits$ = this.store.selectSignal(getGroupedDepositsArray);

  district$ = this.store.selectSignal(getDistrict);
  districtStats$ = this.store.selectSignal(getOrderedDistrictStatisticsByYear);
  districtMean$ = computed(() =>
    Math.round(this.districtStats$().reduce((sum, current) => sum + current.averageDeposits, 0) / this.districtStats$().length)
  );

  tips$ = this.store.selectSignal(getRandomInsightsTips);

  readonly InsightsTimeMode = InsightsTimeMode;
  readonly InsightsViewMode = InsightsViewMode;

  availableYears = {
    min: 2023,
    max: new Date().getFullYear(),
  };

  elMain = viewChild<any>('DashboardMain');

  get currentYear() {
    return new Date().getFullYear();
  }

  activeYearEffect = effect(
    () => {
      let activeyear = this.activeYear$();
      if (activeyear < this.availableYears.min) {
        activeyear = new Date().getFullYear();
        this.store.dispatch(Go({ path: [], queryParams: { year: new Date().getFullYear() }, extras: { queryParamsHandling: 'merge' } }));
      }
    },
    { allowSignalWrites: true }
  );

  gotoMain($event: any) {
    this.elMain()?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
