import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Deposit } from '../../models/deposit.model';
import { InsightsTimeMode } from '../../models';
import { groupedDeposits } from '../../models/deposit-group.model';
import { isThisMonth } from 'date-fns';

@Component({
  selector: 'app-insights-list',
  templateUrl: './insights-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsListComponent {
  @Input() title: any | null = '';
  @Input() timeMode: InsightsTimeMode = InsightsTimeMode.YEAR;
  @Input() deposits: Deposit[] = [];
  @Input() groupedDeposits: groupedDeposits[] = [];

  get yearCount() {
    return this.groupedDeposits.reduce((accumulator, currentDeposit) => accumulator + currentDeposit.deposits.length, 0);
  }
  isThisMonth(date: string) {
    return isThisMonth(new Date(date));
  }
}
