<div class="section-inset-y bg-space-gray-200">
  <div class="container">
    <code>app-deposit-card</code>

    <ng-container *ngFor="let type of depositCardTypes">
      <code>type: {{ type }}</code>
      <app-deposit-card title="Test" [amount]="20" [type]="type"></app-deposit-card>
    </ng-container>
  </div>
</div>
