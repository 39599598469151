import { createSelector } from '@ngrx/store';
import { getYear } from 'date-fns';
import * as fromFeature from '../reducers';
import * as fromDistrictStatistics from '../reducers/district-statistics.reducer';
import * as fromRoot from '../../../store';

export const getDistrictStatisticstate = createSelector(
  fromFeature.getInsightsState,
  (state: fromFeature.InsightsState) => state.districtStatistics
);

export const getDistrictStatisticsEntities = createSelector(
  getDistrictStatisticstate,
  fromDistrictStatistics.getDistrictStatisticsEntities
);

export const getAllDistrictStatistics = createSelector(getDistrictStatisticsEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]) || [];
});

export const getDistrictStatisticsLoaded = createSelector(getDistrictStatisticstate, fromDistrictStatistics.getDistrictStatisticsLoaded);
export const getDistrictStatisticsLoading = createSelector(getDistrictStatisticstate, fromDistrictStatistics.getDistrictStatisticsLoading);
export const getDistrict = createSelector(getDistrictStatisticstate, fromDistrictStatistics.getDistrict);

export const getOrderedDistrictStatistics = createSelector(getAllDistrictStatistics, DistrictStatistics => {
  let d = [...DistrictStatistics.sort((a, b) => a.month - b.month)];
  return d;
});

export const getOrderedDistrictStatisticsByYear = createSelector(
  getOrderedDistrictStatistics,
  fromRoot.getRouterState,
  (DistrictStatistics, router) => {
    const year = router.state.queryParams['year'] || router.state.params['year'] || getYear(Date.now());

    let d = DistrictStatistics.filter(DistrictStatistics => DistrictStatistics.year === +year);

    return d;
  }
);
