import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Deposit } from '../../models';
export type DepositCardType = 'primary' | 'secondary' | 'secondary-light' | 'light' | 'primary' | 'primary-light';
@Component({
  selector: 'app-deposit-card',
  templateUrl: './deposit-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositCardComponent {
  //@Input() state!: string | 'default';
  //@Input() type!: string | 'monthly';

  // @Input() deposit!: Deposit;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() amount!: number | null;
  @Input() link: string | string[] | null = null;
  @Input() cssClasses: string = '';
  @Input() deposit!: Deposit | null;
  @Input() countAnimation = false;

  @Input() type: DepositCardType = 'light';
  @Input() linkStyle: 'button-icon' | 'button-link' = 'button-link';

  current = false;
}
