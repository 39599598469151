import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/district-statistics.action';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { InsightsService } from '../../services';

export const LoadDistrictStatistics$ = createEffect(
  (actions$ = inject(Actions), service = inject(InsightsService)) => {
    return actions$.pipe(
      ofType(actions.LoadDistrictStatistics),
      mergeMap(({ year }) =>
        service.getDistrictStatistics(year).pipe(
          map(({ district, statistics }) => actions.LoadDistrictStatisticsSuccess({ district, statistics, year })),
          catchError(error => of(actions.LoadDistrictStatisticsFail({ error, year })))
        )
      )
    );
  },
  { functional: true }
);
