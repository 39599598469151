import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DepositCardType } from '../../components/deposit-card/deposit-card.component';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuideComponent {
  depositCardTypes: DepositCardType[] = ['primary', 'secondary', 'secondary-light', 'light', 'primary-light'];
}
