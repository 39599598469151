import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromAuth from '../../auth/store';
import { Observable, combineLatest, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DepositsCurrentYearGuard {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(new Date().getFullYear()).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(year: number): Observable<boolean> {
    return combineLatest([
      this.store.select(fromStore.getDepositsLoaded),
      this.store.select(fromStore.getDepositsLoading),
      this.store.select(fromAuth.getAuthSignedIn),
      this.store.select(fromAuth.getAuthSigningIn),
      this.store.select(fromAuth.getAuthChecked),
      this.store.select(fromAuth.getAuthChecking),
    ]).pipe(
      tap(([loaded, loading, signedIn, signingIn, authChecked]) => {
        if (!authChecked || (authChecked && !signedIn) || signingIn) {
          return;
        }
        if (!loaded.some(a => a === year) && !loading.some(a => a === year)) {
          this.store.dispatch(fromStore.LoadDeposits({ year }));
        }
      }),
      map(([loaded, loading, signedIn, signingIn, authChecked]) => (authChecked && !signedIn) || (signedIn && loaded.some(a => a === year)))
    );
  }
}
