<article class="card deposit-compare-card">
  <h2 class="font-secondary text-secondary mb-4 deposit-compare-card__title type-body-md">
    {{
      'inzicht-vergelijk-card-titel'
        | fromDictionary
        | setValueDict
          : [
              { key: 'wijk', value: districtName },
              { key: 'jaar', value: compareYear }
            ]
    }}
  </h2>

  <main class="d-flex gap-4 align-items-center">
    <figure class="deposit-compare-card__figure"><img [src]="image" /></figure>

    <div class="deposit-compare-card__content" [class.set--negative]="positiveCompare" [innerHTML]="compareText"></div>
  </main>
  <footer class="bg-space-gray-250 row mt-3 deposit-compare-card__footer align-items-md-center">
    @if (tip$()) {
      <div class="col-md-5 deposit-compare-card__footer__text type-body-xxxs mb-3 mb-md-0">{{ tip$().shortText }}</div>
      <div class="col-md-7">
        <a [routerLink]="tip$().button.url ? tip$().button.url : tip$().url" class="button button--secondary button--sm">
          <span class="type-body-xxs">{{ tip$().button.text }}</span>
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </a>
      </div>
    } @else {
      <div class="col-md-5 deposit-compare-card__footer__text type-body-xxxs mb-3 mb-md-0">
        {{ 'inzicht-tips-default-tekst' | fromDictionary }}
      </div>
      <div class="col-md-7">
        <a [routerLink]="'inzicht-tips-default-button-url' | fromDictionary" class="button button--secondary button--sm">
          <span class="type-body-xxs">{{ 'inzicht-tips-default-button-tekst' | fromDictionary }}</span>
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </a>
      </div>
    }
  </footer>
</article>
