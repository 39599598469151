<div class="insight-graph">
  <div echarts [options]="echartOptions$()" (chartInit)="onChartInit($event)" (chartClick)="handleChartClick($event)"></div>

  @if (!months$().length) {
    <div class="insight-graph__empty-state">
      <div class="empty-state">
        <p class="mb-2">{{ 'inzicht-geen-aanbiedingen-zichtbaar-tekst' | fromDictionary }} {{ year() }}.</p>

        <div class="type-body-xxs" [innerHTML]="'inzicht-geen-aanbiedingen-zichtbaar-hulptekst' | fromDictionary"></div>
      </div>
    </div>
  } @else {
    <aside class="row mt-4 mb-6">
      <div class="col-md-6 mb-4 mb-md-0">
        @if (legend$()) {
          <section class="graph-legend">
            @for (legendItem of legend$(); track legendItem.name) {
              <ul class="list-unstyled mb-4 legend__list d-flex flex-wrap gap-5">
                <li class="d-flex gap-2 align-items-center">
                  <span class="legend__square" [ngStyle]="{ 'background-color': legendItem.itemStyle?.color }"></span>
                  <strong class="type-body-xxxs">{{ legendItem.name }}</strong>
                </li>
                @for (line of legendItem.markLine?.data; track line.name) {
                  <li class="d-flex gap-2 align-items-center">
                    <span class="legend legend__dotted" [ngStyle]="{ 'border-top-color': legendItem.markLine?.lineStyle?.color }"></span>
                    <strong class="type-body-xxxs">{{ line.name }}</strong>
                  </li>
                }
              </ul>
            }
          </section>
        }
      </div>

      <div class="col-md-6">
        @if (district()) {
          <app-deposit-compare-card
            *ngIf="averageDepositsDistrict()"
            [compareAddressDeposit]="averageDepositsAddress$()"
            [compareDistrictDeposit]="averageDepositsDistrict()"
            [compareYear]="'' + year()"
            [districtName]="district()"
            [tips]="tips"
          ></app-deposit-compare-card>
        }
      </div>
    </aside>
  }
  <footer class="insight-graph__disclaimer type-body-xxxs">
    {{ 'inzicht-grafiek-onderschrift' | fromDictionary }} {{ lastPublishedOnDate | date: 'd MMMM y' }}
  </footer>
</div>
