<article class="card deposit-card deposit-card--yearly" [ngClass]="'deposit-card--' + type">
  <strong class="h1 font-secondary deposit-card__ammount mb-0" *ngIf="!deposit">
    {{ amount }}
  </strong>

  <div class="" [class.ps-2]="deposit">
    <em class="fs-body-xxxs fw-bold text-light" *ngIf="subtitle">{{ subtitle }}</em>
    <h1 class="type-body-xs fw-normal font-primary deposit-card__title">
      {{ title }}
    </h1>
    <main class="deposit-card__main type-body-xxxs mt-2" *ngIf="deposit">
      <strong class="d-block">
        {{
          ('inzicht-container-omschrijving-' + deposit.containerType.name | fromDictionary).includes('TEXT NOT FOUND')
            ? deposit.description
            : ('inzicht-container-omschrijving-' + deposit.containerType.name | fromDictionary)
        }}
      </strong>
      <span class="d-block">Containernummer {{ deposit.containerNumber }}</span>
      <span>{{ deposit.depositedOn | date : 'dd MMMM, HH:mm uur' : 'UTC' }}</span>
    </main>

    <a [routerLink]="link" class="button button-link button--xxs text-current" *ngIf="!deposit && link && linkStyle === 'button-link'">
      <span>{{ 'bekijk-alle-aanbiedingen-knop-tekst' | fromDictionary }}</span>
      <fstr-icon anchor="icon-arrow-right"></fstr-icon>
    </a>
  </div>
  <a [routerLink]="link" class="button button-icon button--primary ms-auto" *ngIf="!deposit && link && linkStyle === 'button-icon'">
    <span class="sr-only">{{ 'bekijk-alle-aanbiedingen-knop-tekst' | fromDictionary }}</span>
    <fstr-icon anchor="icon-arrow-right"></fstr-icon>
  </a>
  <!--<a class="block-link" *ngIf="routerLink" [routerLink]="routerLink"></a>-->
</article>
