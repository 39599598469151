import * as fromPages from '../reducers/deposits.reducer';
import * as fromDistrictStatistics from '../reducers/district-statistics.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'insights';

export interface InsightsState {
  deposits: fromPages.DepositsState;
  districtStatistics: fromDistrictStatistics.DistrictStatisticsState;
}

export const reducers: ActionReducerMap<InsightsState> = {
  deposits: fromPages.depositReducer,
  districtStatistics: fromDistrictStatistics.districtStatisticsReducer,
};

export const getInsightsState = createFeatureSelector<InsightsState>(featureKey);

export const metaReducers: MetaReducer<InsightsState>[] = !environment.production ? [] : [];
