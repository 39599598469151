import { createSelector } from '@ngrx/store';
import { compareAsc, eachMonthOfInterval, getMonth, getTime, getYear, isThisMonth, isThisYear } from 'date-fns';
import * as fromFeature from '../reducers';
import * as fromDeposits from '../reducers/deposits.reducer';
import { Deposit, InsightsTimeMode, InsightsViewMode } from '../../models';
import * as fromRoot from '../../../store';
import * as fromAdresses from '../../../addresses/store';

export const getDepositState = createSelector(fromFeature.getInsightsState, (state: fromFeature.InsightsState) => state.deposits);

export const getDepositEntities = createSelector(getDepositState, fromDeposits.getDepositsEntities);

export const getAllDeposits = createSelector(getDepositEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]) || [];
});

export const getDepositsLoaded = createSelector(getDepositState, fromDeposits.getDepositsLoaded);
export const getDepositsLoading = createSelector(getDepositState, fromDeposits.getDepositsLoading);

// check if current address has deposits service
export const getHasDepositsMunicipalities = createSelector(
  getDepositState,
  fromAdresses.getAddresState,
  (p, a) => p.municipalities.length > 0 && p.municipalities.some(m => a.address?.municipalityId === m)
);

export const getOrderedDeposits = createSelector(getAllDeposits, fromRoot.getRouterState, (deposits, router) => {
  const viewMode: InsightsViewMode = +(router.state.queryParams['viewMode'] || router.state.params['viewMode'] || 3);

  let d = [...deposits];

  // Sorting
  switch (viewMode) {
    case InsightsViewMode.GRAPH:
      d = deposits?.sort((a, b) => (new Date(a.depositedOn).getTime() >= new Date(b.depositedOn).getTime() ? 1 : -1));
      break;
    case InsightsViewMode.LIST:
      d = deposits?.sort((a, b) => (new Date(b.depositedOn).getTime() >= new Date(a.depositedOn).getTime() ? 1 : -1));
      break;
    default:
      d = deposits?.sort((a, b) => new Date(b.depositedOn).getTime() - new Date(a.depositedOn).getTime());
      break;
  }

  return d;
});

export const getOrderedDepositsByYear = createSelector(getOrderedDeposits, fromRoot.getRouterState, (deposits, router) => {
  const year = router.state.queryParams['year'] || router.state.params['year'] || getYear(Date.now());

  let d = deposits.filter(deposits => {
    return getYear(new Date(deposits.depositedOn)) === +year;
  });

  return d;
});

export const getGroupedDeposits = createSelector(getOrderedDepositsByYear, fromRoot.getRouterState, (deposits, router) => {
  const timeMode: InsightsTimeMode = +(router.state.queryParams['timeMode'] || router.state.params['timeMode'] || 0);

  // Grouping
  return groupByTime(deposits, timeMode);
});

export const getDepositsByMonthYear = createSelector(getOrderedDeposits, fromRoot.getRouterState, (deposits, router) => {
  const year = +router.state.params['year'];
  const month = +router.state.params['month'];

  const result = deposits.filter(d => getYear(new Date(d.depositedOn)) === +year && new Date(d.depositedOn).getUTCMonth() === month - 1);

  return result;
});

export const getGroupedDepositsByMonthYear = createSelector(getDepositsByMonthYear, deposits => {
  const groupedData: Record<string, Deposit> = deposits.reduce((acc: any, obj: Deposit) => {
    const date = new Date(obj.depositedOn).toISOString().split('T')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(obj);
    return acc;
  }, {});

  const results = Object.keys(groupedData).map(key => ({ date: new Date(key), deposits: groupedData[key] }));

  return results;
});

export const getGroupedDepositsArray = createSelector(getOrderedDepositsByYear, fromRoot.getRouterState, (deposits, router) => {
  const year = router.state.queryParams['year'] || getYear(Date.now());

  const endMonth = +year === getYear(Date.now()) ? getMonth(Date.now()) : 11;
  // const filled = fillMonth(groups, year);
  const months = eachMonthOfInterval({ start: new Date(year, 0), end: new Date(year, endMonth) });

  const result = months.map(m => {
    return {
      year,
      month: m.toString(),
      deposits: deposits.filter(d => getYear(new Date(d.depositedOn)) === +year && new Date(d.depositedOn).getUTCMonth() === getMonth(m)),
    };
  });

  return result.reverse();
});

// export const getGroupedDepositsArrayGraph = createSelector(getGroupedDepositsArray, deposits => {
//   const restult = deposits.map(m => {
//     return {
//       dataGroupId: getMonth(new Date(m.month)),
//       value: m.deposits.length,
//       date: m.month,
//     };
//   });
// });

function groupByTime(items: Deposit[], timeMode: InsightsTimeMode): Record<string, any[]> {
  const groups: Record<string, any[]> = {};

  items.forEach(item => {
    const fieldValue = item.depositedOn;
    const month = new Date(fieldValue).toLocaleString('nl-nl', { month: 'long' });
    const year = new Date(fieldValue).getFullYear();
    let key = `${year}`;

    if (timeMode === InsightsTimeMode.MONTH) {
      key = `${month} ${year}`;
    }

    if (!groups[key]) {
      groups[key] = [];
    }

    groups[key].push(item);
  });

  return groups;
}

export const getCurrentMonthDeposits = createSelector(getOrderedDeposits, deposits => {
  const thisWeekDepositos = deposits.filter(i => {
    const iDate = new Date(i.depositedOn);
    return isThisMonth(iDate);
  });

  return thisWeekDepositos;
});

export const getLatestDeposit = createSelector(getOrderedDeposits, deposits => {
  let deposit = deposits ? deposits[0] : null;
  return deposit;
});

export const getCurrentYearDeposits = createSelector(getOrderedDeposits, deposits => {
  const currentYear = deposits.filter(i => {
    const iDate = new Date(i.depositedOn);
    return isThisYear(iDate);
  });

  return currentYear;
});

export const getCurrentYearDepositsAmount = createSelector(getCurrentYearDeposits, deposits => {
  return deposits.length;
});

export const getCurrentMonthDepositsAmount = createSelector(getCurrentMonthDeposits, deposits => {
  return deposits.length;
});
