import { Component, OnInit, ChangeDetectionStrategy, Input, inject, computed, input } from '@angular/core';
import { FromDictionaryPipe, LanguageService, SetValueToDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { Tip } from 'src/app/tips/models';

@Component({
  selector: 'app-deposit-compare-card',
  templateUrl: './deposit-compare-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositCompareCardComponent implements OnInit {
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  private setValueDict = new SetValueToDictionaryPipe();

  @Input() image: string = this.dict.transform('inzicht-vergelijk-card-afbeelding');
  @Input() compareYear!: string;
  @Input() compareAddressDeposit: number = 0;
  @Input() compareDistrictDeposit: number = 0;
  @Input() districtName: string = '';
  @Input() tips: Tip[] = [];

  // buttonText: string = this.dict.transform('inzicht-vergelijk-card-knop-label');
  // buttonLink: string = this.dict.transform('inzicht-vergelijk-card-knop-link');
  // footerText: string = this.dict.transform('inzicht-vergelijk-card-footer-tekst');

  tip$ = computed(() => {
    const insightTips = this.tips;

    if (this.differenceInAmount <= 1) {
      insightTips.filter(tip => tip.category !== null && tip.category === 1);
    } else if (this.differenceInAmount > 1) {
      insightTips.filter(tip => tip.category !== null && tip.category === 2);
    } else {
      insightTips.filter(tip => tip.category !== null && tip.category === 0);
    }

    return insightTips[Math.floor(Math.random() * insightTips.length)];
  });

  constructor() {}

  get differenceInAmount() {
    // const difference = this.compareDistrictDeposit - 4.5;
    const difference = this.compareDistrictDeposit - this.compareAddressDeposit;

    return parseFloat(difference.toFixed(1));
  }

  // check if positive or negative (needed for color and labels)
  get positiveCompare() {
    if (this.differenceInAmount > 0) {
      return false;
    } else {
      return true;
    }
  }

  // Compare text for the card
  get compareText() {
    const differenceValueValue = this.differenceInAmount.toFixed(0).toString().replace('-', ''); // remove comma
    let key = '';

    if (!this.compareAddressDeposit) {
      key = 'inzicht-vergelijk-card-tekst-geen-stortingen';
    } else if (this.differenceInAmount === 0) {
      key = 'inzicht-vergelijk-card-tekst-gelijk';
    } else if (this.positiveCompare) {
      key =
        Math.abs(this.differenceInAmount) < 1 ? 'inzicht-vergelijk-card-tekst-negatief-ongeveer' : 'inzicht-vergelijk-card-tekst-negatief';
    } else {
      key = this.differenceInAmount < -1 ? 'inzicht-vergelijk-card-tekst-positief-ongeveer' : 'inzicht-vergelijk-card-tekst-positief';
    }

    return this.setValueDict.transform(this.dict.transform(key), [
      { key: 'gemiddelde', value: differenceValueValue },
      { key: 'wijk', value: this.districtName },
    ]);
  }

  // Footer key for the dictionary
  get compareFooterKey() {
    let key;

    if (!this.compareAddressDeposit) {
      key = '-geen';
    } else if (this.differenceInAmount === 0) {
      key = '-positief-ongeveer';
    } else if (this.positiveCompare) {
      key = Math.abs(this.differenceInAmount) < 1 ? '-negatief-ongeveer' : '-negatief';
    } else {
      key = this.differenceInAmount < 1 ? '-positief-ongeveer' : '-positief';
    }

    return key;
  }

  ngOnInit() {}
}
