<app-insight-header (gotoYear)="gotoMain($event)"></app-insight-header>

<section class="bg-space-gray-200 insight-dashboard section-inset-b pt-5">
  <div class="container">
    <div class="row insights-list">
      <main class="col-md-8">
        <button (click)="goBack()" class="back--button button button-link button--secondary mb-6">
          <fstr-icon anchor="icon-arrow-left"></fstr-icon>
          <span>{{ 'inzicht-terug-naar-overzicht-knoptekst' | fromDictionary }}</span>
        </button>
        <article #DashboardMain>
          <header class="insight-overview__heading mb-7">
            <h2 class="h4 mb-0 text-secondary font-secondary">{{ pageTitle }}</h2>
            <div class="text-secondary type-body-sm fw-bold" *ngIf="getTotal(deposits$ | async)">
              <strong class="me-1">{{ getTotal(deposits$ | async) }}</strong>
              <span class="d-none d-md-inline">{{ 'aanbiedingen-label' | fromDictionary }}</span>
              <span class="d-md-none">keer</span>
            </div>
          </header>

          <dl class="mb-6" *ngFor="let storting of deposits$ | async">
            <dt class="text-secondary fw-bold h6 font-secondary insights-list__subtitle">{{ storting.date | date : 'EEEE d MMMM' }}</dt>
            <dd class="mb-3" *ngFor="let item of storting.deposits">
              <app-deposit-card [deposit]="item" [type]="'light'"></app-deposit-card>
            </dd>
          </dl>
          <div class="insight-emptystate empty-state" *ngIf="!getTotal(deposits$ | async)">
            <p class="mb-0">{{ 'inzicht-geen-aanbiedingen-zichtbaar-tekst' | fromDictionary }} {{ pageTitle.toLowerCase() }}.</p>
          </div>
        </article>
      </main>
      <aside></aside>
    </div>
  </div>
</section>
