import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Deposit } from '../../models';
import { Store } from '@ngrx/store';
import { getCurrentMonthDepositsAmount, getCurrentYearDepositsAmount, getLatestDeposit } from '../../store';
import { getMonth, getYear } from 'date-fns';

@Component({
  selector: 'app-insight-header',
  templateUrl: './insight-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightHeaderComponent implements OnInit {
  amountCurrentMonth$!: Observable<number>;
  amountCurrentYear$!: Observable<number>;
  latestDeposit$!: Observable<Deposit | null>;
  currentYear = getYear(Date.now());
  currentMonth = getMonth(Date.now());
  @Output() gotoYear = new EventEmitter<boolean>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.amountCurrentYear$ = this.store.select(getCurrentYearDepositsAmount);
    this.amountCurrentMonth$ = this.store.select(getCurrentMonthDepositsAmount);
    this.latestDeposit$ = this.store.select(getLatestDeposit);
  }
  //<a [routerLink]="['/inzicht']" class="no--link" [queryParams]="{ viewMode: 1, year: currentYear, scrollTo: 'DashboardMain' }">
  gotoOverview() {}
}
