import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Signal, computed, effect, inject, input } from '@angular/core';
import { SeriesOption } from 'echarts';
import { ECharts } from 'echarts/core';
import { Deposit } from '../../models/deposit.model';
import { DatePipe } from '@angular/common';
import { isToday, subDays } from 'date-fns';
import { Router } from '@angular/router';
import { FromDictionaryPipe, LanguageService, SetValueToDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-insights-graph-month',
  templateUrl: './insights-graph-month.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsGraphMonthComponent {
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  private setValueDict = new SetValueToDictionaryPipe();
  private cd = inject(ChangeDetectorRef);
  private datePipe = inject(DatePipe);
  private router = inject(Router);

  deposits = input<Deposit[]>([]);
  year = input.required<number>();
  month = input.required<number>();
  averageDepositsDistrict = input<number>(5);
  districtName = input<string>('');

  activeYearDeposits$ = computed(() => this.deposits().filter(a => new Date(a.depositedOn).getFullYear() === this.year()));
  addressDepositsCompare$ = computed(() => this.activeYearDeposits$().length / this.days$().length);

  minDate$ = computed(() => new Date(this.year(), 0, 1));
  maxDate$ = computed(() => {
    const maxDate = this.activeYearDeposits$().reduce(
      (max, obj) => (new Date(obj.depositedOn) > max ? new Date(obj.depositedOn) : max),
      new Date(this.activeYearDeposits$()?.[0]?.depositedOn)
    );

    if (maxDate?.getUTCMonth() < new Date(Date.now()).getUTCMonth()) {
      maxDate.setUTCMonth(new Date(Date.now()).getUTCMonth());
    }

    return maxDate;
  });

  monthDetailData$ = computed(() => {
    return {
      dataGroupId: this.datePipe.transform(new Date().setMonth(this.month()), 'MMMM'),
      data: this.days$().filter(day => day.date.getMonth() === this.month()),
    };
  });

  echartsInstance?: ECharts;
  echartOptions$: Signal<any> = computed(() => {
    const data = this.monthDetailData$();

    return {
      textStyle: {
        fontFamily: '"Open Sans", Verdana, sans-serif',
      },

      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'none',
        },
      },
      xAxis: {
        type: 'category',
        data: data.data.map(item => this.getGroupId(item.date, 'day')),
        axisLine: {
          //onZero: true,
          lineStyle: {
            color: '#D7DEE3',
          },
        },
        axisLabel: {
          color: '#254B8A',
        },
      },
      media: [
        {
          // For screens smaller than 768px
          query: {
            maxWidth: 768,
          },
          option: {
            xAxis: {
              axisLabel: {
                rotate: 45, // rotate the labels by 45 degrees
              },
            },
          },
        },
      ],
      yAxis: {
        type: 'value',
        boundaryGap: [0, 0],
        //interval: 1,
        min: 0,
        max: (a: any) => {
          return a.max < 10 ? 10 : Math.ceil(a.max / 5) * 5; // round up to nearest of 5, but if the lowest is lower than 10 use 10
        },
        axisLine: {
          onZero: true,
        },
        axisLabel: {
          color: '#254B8A',
        },
      },
      grid: {
        left: 35, // Change this value to adjust the position of the chart grid
        right: 0,
        top: 10,
      },
      dataGroupId: '',
      animationDurationUpdate: 500,
      series: {
        type: 'bar',
        id: 'maand',

        z: 100,
        dataGroupId: data?.dataGroupId,
        //data: this.setColorOfCurrentBar(subData.data.map(item => ({ dataGroupId: item[0], value: item[1] }))),
        data: data?.data.map(a =>
          isToday(a.date)
            ? {
                ...a,
                itemStyle: {
                  color: '#8ac249',
                },
              }
            : a
        ),
        name: `${this.setValueDict.transform(this.dict.transform('inzicht-grafiek-gebruiker-stortingen-label'), [{ key: 'jaar', value: this.year() + '' }])}`,
        // z: 100,
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: '#7A8F99',
            width: 2,
          },
          tooltip: {
            show: true,
          },
          data: [
            {
              yAxis: this.averageDepositsDistrict,
              // name: `${this.districtName} gemiddeld in ${this.year}`,
              name: `${this.setValueDict.transform(this.dict.transform('inzicht-grafiek-wijk-gemiddelde-label'), [
                { key: 'wijk', value: this.districtName() },
                { key: 'jaar', value: this.year() + '' },
              ])}`,
            },
            // { type: 'average', name: 'Jouw gemiddelde', lineStyle: { color: '#254B8A' } },
          ],
        },
        //barWidth: 10,
        barMinHeight: 10,
        barMaxWidth: 80,
        barCategoryGap: 10,
        barGap: 10,
        itemStyle: {
          borderRadius: [10, 10, 0, 0],
          color: '#254B8A',
        },
      } as unknown as SeriesOption,
      universalTransition: {
        enabled: true,
        divideShape: 'clone',
      },
    };
  });

  legend$ = computed(() => {
    const opts = this.echartOptions$();
    if (!Array.isArray(opts.series)) {
      return [opts.series];
    }

    return opts.series;
  });

  chartChangeEffect = effect(() => {
    const opts = this.echartOptions$();

    this.echartsInstance?.setOption(
      {
        ...opts,
      },
      true
    );
  });

  // Fill gaps if needed
  days$ = computed((): { groupId: string; date: Date; value: number }[] => {
    if (!this.activeYearDeposits$().length || !this.minDate$() || !this.maxDate$()) {
      return [];
    }

    const arr = this.activeYearDeposits$();
    const dates = arr.map(obj => new Date(obj.depositedOn).toDateString()); // Get an array of unique date strings

    const output = Array.from(
      { length: 365 },
      (_, i) => new Date(this.minDate$().getFullYear(), this.minDate$().getMonth(), this.minDate$().getDate() + i)
    );

    const objs = output.reduce((acc, date) => {
      if (dates.includes(date.toDateString())) {
        acc.push({
          groupId: this.getGroupId(date, 'month'),
          date,
          value: this.activeYearDeposits$().filter(d => new Date(d.depositedOn).toDateString() === date.toDateString()).length,
        });
      } else {
        acc.push({ groupId: this.getGroupId(date, 'month'), date, value: '' });
      }
      return acc;
    }, [] as any[]);

    return objs;
  });

  getGroupId(date: Date, type: 'day' | 'month' | 'year') {
    switch (type) {
      case 'year':
        return this.datePipe.transform(date, 'y');
      case 'month':
        return this.datePipe.transform(date, 'MMMM');
      case 'day':
      default:
        return this.datePipe.transform(date, 'd'); // d MMM
    }
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
  }

  resizeChart() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  // This will not switch main view type
  // This will zoom in on the bar, if it has any subdata
  handleChartClick(event: any) {
    const clickedMonth = event.data?.date?.getMonth();

    console.log(event);

    if (event.data) {
      const monthDetailData = this.monthDetailData$();

      if (monthDetailData || !this.echartsInstance) {
        this.router.navigate([`/inzicht/${this.year()}/${this.month() + 1}`]);
        return;
      }
    }
  }

  get formattedActiveMonth() {
    if (this.month() === null) {
      return null;
    }

    let date = new Date();
    date.setFullYear(this.year());
    date.setMonth(this.month());

    return this.datePipe.transform(date, 'MMMM, y');
  }

  get lastPublishedOnDate() {
    return subDays(new Date(), 8);
  }
}
